import axios from 'axios';
import moment from 'moment-timezone';
import React, { ReactNode, createContext, useState } from 'react';
import { useTimeoutFn } from 'react-use';

const TIME_URL = 'https://time.akamai.com/?iso';

export interface ServerOffsetContextProps {
  serverOffset: number;
  ready: boolean;
  updateServerOffset: () => Promise<void>;
}

const ServerOffsetContext = createContext<ServerOffsetContextProps>({
  serverOffset: 0,
  ready: false,
  updateServerOffset: () => new Promise(() => {}),
});

export const ServerOffsetProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [serverOffset, setServerOffset] = useState<number>(0);
  const [ready, setReady] = useState(false);

  let prevDateTime = Date.now();

  const updateServerOffset = async () => {
    try {
      const start = moment();
      const response = await axios.get(TIME_URL);
      const end = moment();

      const responseTime = end.diff(start, 'milliseconds');

      const local = moment().utc();
      const server = moment(response.data)
        .utc()
        .add(responseTime, 'milliseconds');

      const diff = server.diff(local, 'milliseconds');

      setServerOffset(diff);
    } catch (error) {
      console.log('time error', error);
    }

    setReady(true);
  };

  const checkForTimeChange = async () => {
    const prev = prevDateTime || Date.now();
    const current = Date.now();
    const diff = (current - prev) / 1000;
    const delta = Math.abs(diff);
    prevDateTime = current;

    if (delta >= 30) {
      await updateServerOffset();
    }
  };

  const [, , reset] = useTimeoutFn(() => {
    const run = async () => {
      try {
        await checkForTimeChange();
      } catch (error) {
        console.log(error);
      } finally {
        reset();
      }
    };

    run();
  }, 500);

  React.useEffect(() => {
    updateServerOffset();
  }, []);

  return (
    <ServerOffsetContext.Provider
      value={{
        ready,
        serverOffset,
        updateServerOffset,
      }}
    >
      {children}
    </ServerOffsetContext.Provider>
  );
};

export const useServerOffset = () => {
  const context = React.useContext(ServerOffsetContext);

  if (context === undefined) {
    throw new Error('no provider available');
  }

  return context;
};
