import 'shaka-player/dist/controls.css';

import moment from 'moment-timezone';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import shaka from 'shaka-player/dist/shaka-player.ui';

import { MutationOptions, useApolloClient } from '@apollo/client';

import {
  DrmTokenDocument,
  DrmTokenMutation,
  DrmTokenMutationVariables,
  DrmTokenRentalDocument,
  DrmTokenRentalMutation,
  DrmTokenRentalMutationVariables,
  DrmTokenSupportDocument,
  DrmTokenSupportMutation,
  DrmTokenSupportMutationVariables,
} from '../graphql/types';
import { useServerOffset } from '../hooks/useServerOffset';

const DELTA_TOLERANCE = 20;

const ShakaPlayer: React.FC = () => {
  const videoRef = React.useRef<any>(null);
  const uiRef = useRef<any>(null);
  const controller = React.useRef<any>({});
  const [appData, setAppData] = useState<any>(null);
  const client = useApolloClient();
  const [isIdle, setIdle] = useState(true);
  const { serverOffset } = useServerOffset();

  const getTargetPosition = useCallback(
    (start: any, timezone: string) => {
      const targetPosition =
        moment()
          .tz(timezone)
          .add(serverOffset || 0, 'milliseconds')
          .diff(moment(start).tz(timezone)) / 1000;

      return targetPosition < 0 ? 0 : targetPosition;
    },
    [serverOffset]
  );

  const syncTime = useCallback(() => {
    if (!videoRef.current || !appData || appData.type !== 'event') {
      return;
    }

    const targetPosition = getTargetPosition(
      appData.eventStart,
      appData.eventTimezone
    );

    if (targetPosition === 0) {
      videoRef.current.currentTime = 0;
      return;
    }

    const delta = videoRef.current.currentTime - targetPosition;

    if (Math.abs(delta) > DELTA_TOLERANCE && targetPosition > 30) {
      videoRef.current.currentTime = targetPosition;
    }
  }, [videoRef, appData, getTargetPosition]);

  const [, , reset] = useTimeoutFn(() => {
    syncTime();
    reset();
  }, 60000);

  const onPlay = useCallback(() => {
    syncTime();
    reset();
  }, [syncTime, reset]);

  useEffect(() => {
    if (!videoRef.current || !appData || appData.type !== 'event') {
      return;
    }

    const video = videoRef.current;

    video.addEventListener('play', onPlay);

    return () => {
      video?.removeEventListener('play', onPlay);
    };
  }, [videoRef, onPlay, appData]);

  useEffect(() => {
    shaka.polyfill.installAll();

    const config = {
      addSeekBar: true,
      addBigPlayButton: false,
      overflowMenuButtons: ['captions', 'language'],
      controlPanelElements: [
        'play_pause',
        'time_and_duration',
        'spacer',
        'overflow_menu',
      ],
    };

    const player = new shaka.Player(videoRef.current);
    const ui = new shaka.ui.Overlay(player, uiRef.current, videoRef.current);
    ui.configure(config);

    const appDataCb = (e: any) => {
      setAppData(e);
    };
    const checkIdle = () => {
      setIdle(receiver.isIdle());
    };

    const receiver = new shaka.cast.CastReceiver(
      videoRef.current,
      player,
      appDataCb
    );
    receiver.addEventListener('caststatuschanged', checkIdle);

    controller.current = {
      player,
      receiver,
      ui,
      videoElement: videoRef.current,
    };

    return () => {
      receiver.removeEventListener('caststatuschanged', checkIdle);
      player?.destroy();
      ui?.destroy();
      receiver?.destroy();
    };
  }, []);

  useEffect(() => {
    if (!controller.current || !controller.current.player) {
      return;
    }

    controller.current.player
      .getNetworkingEngine()
      .registerRequestFilter(async (type: any, request: any) => {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
          try {
            const options: MutationOptions = {
              mutation: DrmTokenDocument,
              fetchPolicy: 'no-cache',
              context: {
                headers: {
                  authorization: appData.token ? `Bearer ${appData.token}` : '',
                },
              },
            };

            if (appData.type === 'support') {
              const { data } = await client.mutate<
                DrmTokenSupportMutation,
                DrmTokenSupportMutationVariables
              >({
                ...options,
                mutation: DrmTokenSupportDocument,
                variables: {
                  sessionId: request.sessionId,
                },
              });

              request.headers['x-dt-auth-token'] = data?.drmTokenSupport.token;
            } else if (appData.type === 'rental') {
              const { data } = await client.mutate<
                DrmTokenRentalMutation,
                DrmTokenRentalMutationVariables
              >({
                mutation: DrmTokenRentalDocument,
                variables: {
                  sessionId: request.sessionId,
                  rentalId: appData.rentalId,
                },
                context: {
                  headers: {
                    authorization: appData.token
                      ? `Bearer ${appData.token}`
                      : '',
                  },
                },
              });

              request.headers['x-dt-auth-token'] = data?.drmTokenRental.token;
            } else {
              const { data } = await client.mutate<
                DrmTokenMutation,
                DrmTokenMutationVariables
              >({
                mutation: DrmTokenDocument,
                variables: {
                  sessionId: request.sessionId,
                  eventId: appData.eventId,
                },
                context: {
                  headers: {
                    authorization: appData.token
                      ? `Bearer ${appData.token}`
                      : '',
                  },
                },
              });

              request.headers['x-dt-auth-token'] = data?.drmToken.token;
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
  }, [controller, appData, client]);

  return (
    <>
      {/* <div style={{ position: 'absolute', zIndex: 100, color: '#fff' }}>
        <div>Event: {`${appData ? appData.eventId : ''}`}</div>
        <div>Token: {`${appData ? appData.token : ''}`}</div>
        <div>Type: {`${appData ? appData.type : ''}`}</div>
        <div>Idle: {isIdle ? 'yup' : 'nope'}</div>
        <div>ServerOffset: {serverOffset}</div>
        <div>Message: {message}</div>
      </div> */}
      <div
        ref={uiRef}
        id="videoContainer"
        style={{ width: '100%', height: '100%' }}
      >
        <video ref={videoRef} height="100%" width="100%" />
      </div>
    </>
  );
};

export default ShakaPlayer;
