import React from 'react';
import ShakaReceiver from './components/ShakaReceiver';
import { ServerOffsetProvider } from './hooks/useServerOffset';
// import * as Sentry from '@sentry/react';
import { ApolloProvider } from '@apollo/client';

import apolloClient from './lib/apolloClient';

const App = () => {
  return (
    // <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <ApolloProvider client={apolloClient}>
        <ServerOffsetProvider>
          <ShakaReceiver />
        </ServerOffsetProvider>
      </ApolloProvider>
    // </Sentry.ErrorBoundary>
  );
};

export default App;
