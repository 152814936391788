import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AgoraRtcTokenInput = {
  channelName: Scalars['String']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

export type AgoraToken = {
  __typename?: 'AgoraToken';
  token: Scalars['String']['output'];
};

export type Asset = {
  __typename?: 'Asset';
  bucket?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  filetype?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  palette?: Maybe<AssetPalette>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AssetDownload = {
  __typename?: 'AssetDownload';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type AssetMetadataInput = {
  language: Scalars['String']['input'];
  originalFileName: Scalars['String']['input'];
};

export type AssetPalette = {
  __typename?: 'AssetPalette';
  averageLuminance?: Maybe<Scalars['Float']['output']>;
  colors?: Maybe<Array<PaletteColor>>;
  muted?: Maybe<PaletteColor>;
  mutedDark?: Maybe<PaletteColor>;
  mutedLight?: Maybe<PaletteColor>;
  vibrant?: Maybe<PaletteColor>;
  vibrantDark?: Maybe<PaletteColor>;
  vibrantLight?: Maybe<PaletteColor>;
};

export type AssetPresignedPost = {
  __typename?: 'AssetPresignedPost';
  fields: Scalars['String']['output'];
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AssetPresignedPostInput = {
  contentType: Scalars['String']['input'];
  key: Scalars['String']['input'];
  metadata?: InputMaybe<AssetMetadataInput>;
};

export type AttendeeInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type AuthToken = {
  __typename?: 'AuthToken';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  user: User;
};

export type BanChatMemberInput = {
  channelId: Scalars['String']['input'];
  memberId: Scalars['String']['input'];
};

export type Branding = {
  __typename?: 'Branding';
  createdAt: Scalars['DateTime']['output'];
  focalPoint?: Maybe<FocalPoint>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Asset>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Broadcast = {
  __typename?: 'Broadcast';
  chatChannel?: Maybe<ChatChannel>;
  chatChannelId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHost: Scalars['Boolean']['output'];
  isParticipant: Scalars['Boolean']['output'];
  isRecordable: Scalars['Boolean']['output'];
  participants?: Maybe<Array<BroadcastParticipant>>;
  recordable: Scalars['Boolean']['output'];
  status: BroadcastStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type BroadcastInviteResponse = {
  __typename?: 'BroadcastInviteResponse';
  event?: Maybe<Event>;
  id: Scalars['String']['output'];
  isValid: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type BroadcastParticipant = {
  __typename?: 'BroadcastParticipant';
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status: ParticipantStatus;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type BroadcastRecording = {
  __typename?: 'BroadcastRecording';
  channelName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  resourceId?: Maybe<Scalars['String']['output']>;
  sid?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum BroadcastStatus {
  Live = 'LIVE',
  Offline = 'OFFLINE'
}

export type Bumper = {
  __typename?: 'Bumper';
  description: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type Card = {
  __typename?: 'Card';
  brand: Scalars['String']['output'];
  expiryMonth?: Maybe<Scalars['Float']['output']>;
  expiryYear?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export type ChangeCapacityRate = {
  __typename?: 'ChangeCapacityRate';
  capacity: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  total: Scalars['Int']['output'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type Charge = {
  __typename?: 'Charge';
  expMonth: Scalars['String']['output'];
  expYear: Scalars['String']['output'];
  id: Scalars['String']['output'];
  last4: Scalars['String']['output'];
  network: Scalars['String']['output'];
};

export type ChatChannel = {
  __typename?: 'ChatChannel';
  createdAt: Scalars['DateTime']['output'];
  eventId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ChatMember = {
  __typename?: 'ChatMember';
  banned: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  moderator: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  channel: ChatChannel;
  channelId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sender: ChatMember;
  senderId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ClipSegment = {
  __typename?: 'ClipSegment';
  path: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type ClosedCaptions = {
  __typename?: 'ClosedCaptions';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  language: Scalars['String']['output'];
};

export type Collection = {
  __typename?: 'Collection';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  projects: Array<Project>;
  title: Scalars['String']['output'];
  total: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type CollectionProjectsArgs = {
  listed?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CollectionTotalArgs = {
  listed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConnectAccount = {
  __typename?: 'ConnectAccount';
  accountLink?: Maybe<ConnectAccountLink>;
  businessProfile?: Maybe<ConnectBusinessProfile>;
  businessType?: Maybe<Scalars['String']['output']>;
  capabilitiesTransfer?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  defaultCurrency?: Maybe<Scalars['String']['output']>;
  externalAccounts: Array<ConnectExternalAccount>;
  id: Scalars['ID']['output'];
  payoutInterval: Scalars['String']['output'];
  payoutsEnabled: Scalars['Boolean']['output'];
  requirements: ConnectRequirements;
  type: Scalars['String']['output'];
};

export type ConnectAccountLink = {
  __typename?: 'ConnectAccountLink';
  url: Scalars['String']['output'];
};

export type ConnectAddress = {
  __typename?: 'ConnectAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type ConnectBusinessProfile = {
  __typename?: 'ConnectBusinessProfile';
  mcc?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  supportAddress?: Maybe<ConnectAddress>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportPhone?: Maybe<Scalars['String']['output']>;
  supportUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ConnectBusinessProfileInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  productionDescription?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectCompanyInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  person?: InputMaybe<ConnectPersonInput>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2: Scalars['String']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectExternalAccount = {
  __typename?: 'ConnectExternalAccount';
  bankName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  defaultForCurrency: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  last4: Scalars['String']['output'];
  routingNumber?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type ConnectIndividualInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2: Scalars['String']['input'];
};

export type ConnectPersonInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  dobDay: Scalars['Float']['input'];
  dobMonth: Scalars['Float']['input'];
  dobYear: Scalars['Float']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  ssnLast4: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type ConnectRequirements = {
  __typename?: 'ConnectRequirements';
  currentDeadline?: Maybe<Scalars['Int']['output']>;
  currentlyDue: Array<Scalars['String']['output']>;
  disabledReason?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<ConnectRequirementsError>>;
  eventuallyDue: Array<Scalars['String']['output']>;
  pastDue: Array<Scalars['String']['output']>;
  pendingVerification: Array<Scalars['String']['output']>;
};

export type ConnectRequirementsError = {
  __typename?: 'ConnectRequirementsError';
  code: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  requirement: Scalars['String']['output'];
};

export type ConnectStripeAccountInput = {
  code: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type CreateCollectionInput = {
  title: Scalars['String']['input'];
};

export type CreateConnectAccountInput = {
  businessProfile?: InputMaybe<ConnectBusinessProfileInput>;
  businessType: Scalars['String']['input'];
  company?: InputMaybe<ConnectCompanyInput>;
  country: Scalars['String']['input'];
  individual?: InputMaybe<ConnectIndividualInput>;
  organizationId: Scalars['String']['input'];
};

export type CreateConnectAccountLinkInput = {
  organizationId: Scalars['String']['input'];
  refreshUrl: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};

export type CreateConnectPersonInput = {
  organizationId: Scalars['String']['input'];
  person: ConnectPersonInput;
};

export type CreateCreditInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  filmId: Scalars['String']['input'];
  job?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  tmdbId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type CreateEventInput = {
  capacity: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  featureDescription?: InputMaybe<Scalars['String']['input']>;
  hostedByOverride?: InputMaybe<Scalars['String']['input']>;
  licenseOption?: InputMaybe<Scalars['String']['input']>;
  listed: Scalars['Boolean']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  payment?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Int']['input'];
  projectId: Scalars['String']['input'];
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  rateCardId?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['DateTime']['input'];
  timezone: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: EventType;
  venue?: InputMaybe<CreateVenueInput>;
  virtual?: Scalars['Boolean']['input'];
};

export type CreateEventSpeakerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['String']['input'];
  file?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<FocalPointInput>;
  name: Scalars['String']['input'];
};

export type CreateExternalAccountInput = {
  organizationId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CreateFilmAssetInput = {
  filmId: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type CreateFilmInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  tmdbId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGenreInput = {
  name: Scalars['String']['input'];
};

export type CreateIndustryEventInput = {
  description: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateLiveStreamInput = {
  eventId: Scalars['String']['input'];
};

export type CreateOrganizationInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateOrganizationInternalInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreatePlaylist = {
  __typename?: 'CreatePlaylist';
  bumpers: Array<Bumper>;
  subtitles?: Maybe<Array<CreatePlaylistSubtitle>>;
  videos: Array<Video>;
};

export type CreatePlaylistInput = {
  bumperIds: Array<Scalars['String']['input']>;
  changeDefault: Scalars['Boolean']['input'];
  default: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  subtitleIds: Array<Scalars['String']['input']>;
  videoId: Scalars['String']['input'];
};

export type CreatePlaylistSubtitle = {
  __typename?: 'CreatePlaylistSubtitle';
  hasCC?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  language: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CreateProjectInput = {
  allowOnDemand?: Scalars['Boolean']['input'];
  allowPhysical?: Scalars['Boolean']['input'];
  allowRental?: Scalars['Boolean']['input'];
  allowVirtual?: Scalars['Boolean']['input'];
  availabilityEnd?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStart?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  end?: InputMaybe<Scalars['DateTime']['input']>;
  filmId: Scalars['String']['input'];
  listed?: Scalars['Boolean']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  playlistEnabled?: Scalars['Boolean']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  rentalPrice?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  ticketMax?: InputMaybe<Scalars['Int']['input']>;
  ticketMin?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  vmsEnabled?: Scalars['Boolean']['input'];
};

export type CreatePromotionCodeInput = {
  amountOff?: InputMaybe<Scalars['Int']['input']>;
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  percentOff?: InputMaybe<Scalars['Int']['input']>;
  resourceId: Scalars['String']['input'];
  resourceType: Scalars['String']['input'];
  type: Scalars['String']['input'];
  usageLimit: Scalars['Int']['input'];
};

export type CreateRateCardInput = {
  items: Array<RateCardLineInput>;
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  unitAmount: Scalars['Int']['input'];
};

export type CreateReviewInput = {
  content: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  source: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type CreateScreenerInput = {
  end: Scalars['DateTime']['input'];
  projectId: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
};

export type CreateSpeakerInput = {
  description: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  physical: Scalars['Boolean']['input'];
  virtual: Scalars['Boolean']['input'];
};

export type CreateVenueAssetInput = {
  key: Scalars['String']['input'];
  venueId: Scalars['String']['input'];
};

export type CreateVenueInput = {
  attributes: Array<VenueAttributeInput>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  placeId: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type Credit = {
  __typename?: 'Credit';
  createdAt: Scalars['DateTime']['output'];
  department?: Maybe<Scalars['String']['output']>;
  film: Film;
  id: Scalars['ID']['output'];
  job?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Float']['output']>;
  person?: Maybe<Person>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DeleteBroadcastParticipantInput = {
  participantId: Scalars['String']['input'];
};

export type DeleteChatMessageInput = {
  channelId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
};

export type DeleteEventBrandingInput = {
  eventId: Scalars['String']['input'];
};

export type DeleteEventSpeakerAssetInput = {
  eventSpeakerId: Scalars['String']['input'];
};

export type DeleteEventSpeakerInput = {
  id: Scalars['String']['input'];
};

export type DeleteExternalAccountInput = {
  externalAccountId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type DeleteOrganizationAvatarInput = {
  organizationId: Scalars['String']['input'];
};

export type DeletePlaylistInput = {
  id: Scalars['String']['input'];
};

export type DeleteVenueAssetInput = {
  assetId: Scalars['String']['input'];
  venueId: Scalars['String']['input'];
};

export type DrmToken = {
  __typename?: 'DrmToken';
  id: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type DrmTokenRentalInput = {
  rentalId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};

export type EncodedVideo = {
  __typename?: 'EncodedVideo';
  createdAt: Scalars['String']['output'];
  drmAssetId: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  processedVideos: Array<Scalars['String']['output']>;
  projectId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  videoIngestId: Scalars['String']['output'];
};

export type EncodingJob = {
  __typename?: 'EncodingJob';
  acceleratedTranscoding?: Maybe<Scalars['String']['output']>;
  archiveSource?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  destBucket?: Maybe<Scalars['String']['output']>;
  encodeJobId?: Maybe<Scalars['String']['output']>;
  encodingProfile?: Maybe<Scalars['String']['output']>;
  errorDetails?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  filmId?: Maybe<Scalars['String']['output']>;
  frameCapture?: Maybe<Scalars['Boolean']['output']>;
  frameCaptureHeight?: Maybe<Scalars['Float']['output']>;
  frameCaptureWidth?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  inputRotate?: Maybe<Scalars['String']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['String']['output']>;
  selectedAudioTracks?: Maybe<Scalars['String']['output']>;
  srcBucket?: Maybe<Scalars['String']['output']>;
  srcHeight?: Maybe<Scalars['Float']['output']>;
  srcMediainfo?: Maybe<Scalars['String']['output']>;
  srcVideo?: Maybe<Scalars['String']['output']>;
  srcWidth?: Maybe<Scalars['String']['output']>;
  workflowName?: Maybe<Scalars['String']['output']>;
  workflowStatus: Scalars['String']['output'];
  workflowTrigger?: Maybe<Scalars['String']['output']>;
};

export type Event = {
  __typename?: 'Event';
  availability: Scalars['Float']['output'];
  branding?: Maybe<Branding>;
  capacity: Scalars['Float']['output'];
  chatChannel?: Maybe<ChatChannel>;
  chatChannelId?: Maybe<Scalars['String']['output']>;
  chatEnabled: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  end: Scalars['DateTime']['output'];
  featureDescription?: Maybe<Scalars['String']['output']>;
  flexTicketEnabled: Scalars['Boolean']['output'];
  hostedBy: Scalars['String']['output'];
  hostedByOverride?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isGeoBlocked: Scalars['Boolean']['output'];
  listed: Scalars['Boolean']['output'];
  order?: Maybe<Order>;
  orders?: Maybe<Array<Order>>;
  organization: Organization;
  playlistDrmAssetId?: Maybe<Scalars['String']['output']>;
  playlistId?: Maybe<Scalars['String']['output']>;
  priceLabel: Scalars['String']['output'];
  project: Project;
  published: Scalars['Boolean']['output'];
  rateCard?: Maybe<RateCard>;
  salesTotal?: Maybe<Scalars['Float']['output']>;
  slug: Scalars['String']['output'];
  speakers?: Maybe<Array<EventSpeaker>>;
  start: Scalars['DateTime']['output'];
  status: EventStatus;
  ticketClasses: Array<TicketClass>;
  timezone?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<EventType>;
  updatedAt: Scalars['DateTime']['output'];
  venue?: Maybe<Venue>;
  virtual: Scalars['Boolean']['output'];
};

export type EventSpeaker = {
  __typename?: 'EventSpeaker';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  focalPoint?: Maybe<FocalPoint>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  picture?: Maybe<Asset>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum EventStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Ended = 'ENDED',
  Pending = 'PENDING'
}

export enum EventType {
  InPerson = 'IN_PERSON',
  OnDemand = 'ON_DEMAND',
  Virtual = 'VIRTUAL'
}

export type FeaturedCollection = {
  __typename?: 'FeaturedCollection';
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Scalars['String']['output']>>;
  type: FeaturedCollectionType;
};

export enum FeaturedCollectionType {
  BannerProject = 'BANNER_PROJECT',
  FeaturedEvents = 'FEATURED_EVENTS',
  FeaturedProjects = 'FEATURED_PROJECTS'
}

export type Film = {
  __typename?: 'Film';
  assets?: Maybe<Array<Asset>>;
  banner?: Maybe<Asset>;
  branding?: Maybe<Branding>;
  createdAt: Scalars['DateTime']['output'];
  credits?: Maybe<Array<Credit>>;
  description?: Maybe<Scalars['String']['output']>;
  gallery?: Maybe<Array<Gallery>>;
  genre?: Maybe<Scalars['String']['output']>;
  genres?: Maybe<Array<Genre>>;
  hasSpeakers: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  industryEvents?: Maybe<Array<IndustryEvent>>;
  internalDescription?: Maybe<Scalars['String']['output']>;
  poster?: Maybe<Asset>;
  published: Scalars['Boolean']['output'];
  reviews?: Maybe<Array<Review>>;
  runtime?: Maybe<Scalars['Int']['output']>;
  speakers?: Maybe<Array<Speaker>>;
  tagline?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  tmdbId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  year?: Maybe<Scalars['Int']['output']>;
  youtubeTrailer?: Maybe<Scalars['String']['output']>;
};

export type FilmGenreInput = {
  id: Scalars['String']['input'];
};

export type FmcAbortMultipartUploadInput = {
  fileId: Scalars['String']['input'];
  fileKey: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcAccountLink = {
  __typename?: 'FmcAccountLink';
  created: Scalars['Float']['output'];
  expiresAt: Scalars['Float']['output'];
  object: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum FmcAssetType {
  Banner = 'BANNER',
  Poster = 'POSTER',
  Promotion = 'PROMOTION'
}

export type FmcCompleteMultipartUpload = {
  __typename?: 'FmcCompleteMultipartUpload';
  id: Scalars['String']['output'];
  location: Scalars['String']['output'];
};

export type FmcCompleteMultipartUploadInput = {
  fileId: Scalars['String']['input'];
  fileKey: Scalars['String']['input'];
  originalFilename: Scalars['String']['input'];
  parts: Array<FmcUploadPartInput>;
  projectId: Scalars['String']['input'];
};

export type FmcConnectAccount = {
  __typename?: 'FmcConnectAccount';
  accountLink: FmcAccountLink;
  id: Scalars['String']['output'];
  requirements: FmcConnectRequirements;
  type: Scalars['String']['output'];
};

export type FmcConnectError = {
  __typename?: 'FmcConnectError';
  code: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  requirement: Scalars['String']['output'];
};

export type FmcConnectRequirements = {
  __typename?: 'FmcConnectRequirements';
  currentlyDue: Array<Scalars['String']['output']>;
  errors: Array<FmcConnectError>;
  eventuallyDue: Array<Scalars['String']['output']>;
  pastDue: Array<Scalars['String']['output']>;
  pendingVerification: Array<Scalars['String']['output']>;
};

export type FmcCreateAssetInput = {
  assetType: FmcAssetType;
  key: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcCreateConnectAccountInput = {
  country: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  refreshUrl: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};

export type FmcCreateCreditInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  filmId: Scalars['String']['input'];
  job?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['String']['input'];
  tmdbId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type FmcCreateFilmProjectInput = {
  description: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  runtime: Scalars['Float']['input'];
  title: Scalars['String']['input'];
  tmdbId?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Float']['input'];
};

export type FmcCreateGalleryAssetInput = {
  key: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcCreateIndustryEventInput = {
  description: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type FmcCreateMultipartUploadInput = {
  filename: Scalars['String']['input'];
  originalFilename: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcCreateReviewInput = {
  content: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  source: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type FmcCreateSpeakerInput = {
  description: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  physical: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
  virtual: Scalars['Boolean']['input'];
};

export type FmcDeleteAssetInput = {
  assetId: Scalars['String']['input'];
  assetType: FmcAssetType;
  projectId: Scalars['String']['input'];
};

export type FmcDeleteCreditInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcDeleteGalleryAssetInput = {
  assetId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcDeleteIndustryEventInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcDeleteReviewInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcDeleteSpeakerInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcDeleteSubtitleInput = {
  index: Scalars['Float']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcDeleteVideoReferenceInput = {
  projectId: Scalars['String']['input'];
};

export type FmcMultipartUpload = {
  __typename?: 'FmcMultipartUpload';
  fileId: Scalars['String']['output'];
  fileKey: Scalars['String']['output'];
};

export type FmcPresignedUrl = {
  __typename?: 'FmcPresignedUrl';
  url: Scalars['String']['output'];
};

export type FmcPresignedUrlInput = {
  fileId: Scalars['String']['input'];
  fileKey: Scalars['String']['input'];
  partNumber: Scalars['Int']['input'];
  projectId: Scalars['String']['input'];
};

export type FmcPublishProjectInput = {
  projectId: Scalars['String']['input'];
};

export type FmcSaveSubtitleInput = {
  filename: Scalars['String']['input'];
  hasCC: Scalars['Boolean']['input'];
  language: Scalars['String']['input'];
  originalFilename: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type FmcSubtitle = {
  __typename?: 'FmcSubtitle';
  hasCC?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  language: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FmcUpdateBasicsInput = {
  description: Scalars['String']['input'];
  genre: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type FmcUpdateCreditInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  filmId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  job?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['String']['input'];
  tmdbId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type FmcUpdateFilmTrailer = {
  filmId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  trailer?: InputMaybe<Scalars['String']['input']>;
};

export type FmcUpdateIndustryEventInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type FmcUpdatePricingInput = {
  licenseOptional: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
  rentalPrice?: InputMaybe<Scalars['Int']['input']>;
  ticketMax?: InputMaybe<Scalars['Int']['input']>;
  ticketMin?: InputMaybe<Scalars['Int']['input']>;
};

export type FmcUpdateReviewInput = {
  content: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  source: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type FmcUpdateRightsInput = {
  allowOnDemand: Scalars['Boolean']['input'];
  allowPhysical: Scalars['Boolean']['input'];
  allowRental: Scalars['Boolean']['input'];
  allowVirtual: Scalars['Boolean']['input'];
  end: Scalars['DateTime']['input'];
  geoBlacklist: Array<Scalars['String']['input']>;
  geoWhitelist: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  listed: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
};

export type FmcUpdateSpeakerInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  physical: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
  virtual: Scalars['Boolean']['input'];
};

export type FmcUploadPart = {
  __typename?: 'FmcUploadPart';
  eTag: Scalars['String']['output'];
  lastModified: Scalars['DateTime']['output'];
  partNumber: Scalars['Float']['output'];
  size: Scalars['Float']['output'];
};

export type FmcUploadPartInput = {
  eTag: Scalars['String']['input'];
  partNumber: Scalars['Float']['input'];
};

export type FmcVideo = {
  __typename?: 'FmcVideo';
  id: Scalars['String']['output'];
  originalFilename: Scalars['String']['output'];
  subtitles: Array<FmcSubtitle>;
};

export type FocalPoint = {
  __typename?: 'FocalPoint';
  height: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
  zoom: Scalars['Float']['output'];
};

export type FocalPointInput = {
  height?: Scalars['Float']['input'];
  width?: Scalars['Float']['input'];
  x?: Scalars['Float']['input'];
  y?: Scalars['Float']['input'];
  zoom?: Scalars['Float']['input'];
};

export type Gallery = {
  __typename?: 'Gallery';
  bucket?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  filetype?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  palette?: Maybe<AssetPalette>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  type: GalleryContentType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum GalleryContentType {
  Image = 'IMAGE',
  Youtube = 'YOUTUBE'
}

export type GalleryInput = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filetype?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  type: GalleryContentType;
};

export type Genre = {
  __typename?: 'Genre';
  count?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type GeoRestriction = {
  __typename?: 'GeoRestriction';
  isBlocked: Scalars['Boolean']['output'];
};

export type HostInquiryInput = {
  genres: Array<Scalars['String']['input']>;
  organizationType: Scalars['String']['input'];
  screeningType: Scalars['String']['input'];
};

export type ImportCreditInput = {
  filmId: Scalars['String']['input'];
  selected?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IndustryEvent = {
  __typename?: 'IndustryEvent';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  film: Film;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InviteBroadcastParticipantInput = {
  broadcastId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type LiveStream = {
  __typename?: 'LiveStream';
  arn: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  eventId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ingestEndpoint: Scalars['String']['output'];
  playbackUrl: Scalars['String']['output'];
  position: LiveStreamPosition;
  stage: Scalars['Boolean']['output'];
  status: LiveStreamStatus;
  streamKey: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum LiveStreamPosition {
  Main = 'MAIN',
  Sidebar = 'SIDEBAR'
}

export enum LiveStreamStatus {
  Live = 'LIVE',
  Offline = 'OFFLINE'
}

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateRateCard: RateCard;
  addOrganizationMember: Organization;
  approveEvent: Event;
  assetUploadPresigned: AssetPresignedPost;
  authenticate: AuthToken;
  banChatMember: ChatMember;
  cancelEvent: Event;
  changePassword: OkPayload;
  connectStripeAccount: Organization;
  createCard: Card;
  createCollection: Collection;
  createConnectAccount: Organization;
  createConnectAccountLink: ConnectAccountLink;
  createConnectPerson: OkPayload;
  createCredit: Credit;
  createEvent: Event;
  createEventSpeaker: EventSpeaker;
  createExternalAccount: OkPayload;
  createFilm: Film;
  createFilmAsset: Film;
  createGenre: Genre;
  createIndustryEvent: IndustryEvent;
  createLiveStream: LiveStream;
  createOrganization: Organization;
  createOrganizationInternal: Organization;
  createPlaylist: Playlist;
  createProject: Project;
  createPromotionCode: PromotionCode;
  createRateCard: RateCard;
  createReview: Review;
  createScreener: Screener;
  createSpeaker: Speaker;
  createVenueAsset: Venue;
  deactivateRateCard: RateCard;
  deleteAsset: OkPayload;
  deleteBroadcastParticipant: BroadcastParticipant;
  deleteCard: Card;
  deleteChatMessage: ChatMessage;
  deleteCollection: OkPayload;
  deleteCredit: OkPayload;
  deleteEventBranding: Event;
  deleteEventSpeaker: OkPayload;
  deleteEventSpeakerAsset: EventSpeaker;
  deleteExternalAccount: OkPayload;
  deleteGenre: OkPayload;
  deleteIndustryEvent: OkPayload;
  deleteOrganizationAvatar: Organization;
  deletePlaylist: OkPayload;
  deleteRateCard: OkPayload;
  deleteReview: OkPayload;
  deleteScreener: OkPayload;
  deleteSpeaker: OkPayload;
  deleteVenueAsset: Venue;
  drmToken: DrmToken;
  drmTokenConsole: DrmToken;
  drmTokenOffline: DrmToken;
  drmTokenRental: DrmToken;
  drmTokenScreener: DrmToken;
  drmTokenSupport: DrmToken;
  fmcAbortMultipartUpload: OkPayload;
  fmcCompleteMultipartUpload: FmcCompleteMultipartUpload;
  fmcCreateAsset: Asset;
  fmcCreateConnectAccount: FmcConnectAccount;
  fmcCreateCredit: Credit;
  fmcCreateFilmProject: Project;
  fmcCreateGalleryAsset: Array<Gallery>;
  fmcCreateIndustryEvent: IndustryEvent;
  fmcCreateMultipartUpload: FmcMultipartUpload;
  fmcCreateReview: Review;
  fmcCreateSpeaker: Speaker;
  fmcDeleteAsset: OkPayload;
  fmcDeleteCredit: OkPayload;
  fmcDeleteGalleryAsset: Array<Gallery>;
  fmcDeleteIndustryEvent: OkPayload;
  fmcDeleteReview: OkPayload;
  fmcDeleteSpeaker: OkPayload;
  fmcDeleteSubtitle: OkPayload;
  fmcDeleteVideoReference: OkPayload;
  fmcPresignedUrl: FmcPresignedUrl;
  fmcPublishProject: Project;
  fmcSaveSubtitle: FmcSubtitle;
  fmcUpdateBasics: Project;
  fmcUpdateCredit: Credit;
  fmcUpdateFilmTrailer: Film;
  fmcUpdateIndustryEvent: IndustryEvent;
  fmcUpdatePricing: Project;
  fmcUpdateReview: Review;
  fmcUpdateRights: Project;
  fmcUpdateSpeaker: Speaker;
  hostInquiry: OkPayload;
  importCredits: Array<Credit>;
  inviteBroadcastParticipant: BroadcastParticipant;
  orderPayment: Order;
  organizationCreateConnectAccount: ConnectAccount;
  passwordReset: OkPayload;
  publishEvent: Event;
  publishProject: Project;
  purchaseRental: Rental;
  purchaseTickets: TicketOrder;
  redeemBroadcastInvite: BroadcastParticipant;
  refundOrder: Order;
  refundTicket: TicketOrder;
  removeOrganizationMember: Organization;
  resendBroadcastParticipantInvite: OkPayload;
  resetRental: Rental;
  resetTicket: Ticket;
  rtcToken: AgoraToken;
  rtmToken: AgoraToken;
  sendChatMessage: ChatMessage;
  setExternalAccountAsDefault: OkPayload;
  setFilmBanner: Film;
  setFilmPoster: Film;
  setPayoutSchedule: OkPayload;
  setVideoAudioTracks: OkPayload;
  setYouTubeTrailer: Film;
  signup: AuthToken;
  startBroadcastRecording: BroadcastRecording;
  startRental: Rental;
  stopBroadcastRecording: BroadcastRecording;
  ticketStartRental: Ticket;
  triggerPasswordReset: OkPayload;
  unpublishEvent: Event;
  unpublishProject: Project;
  updateAsset: Asset;
  updateBroadcastParticipant: BroadcastParticipant;
  updateBroadcastRecordable: Broadcast;
  updateBroadcastStatus: Broadcast;
  updateCollection: Collection;
  updateEvent: Event;
  updateEventBranding: Event;
  updateEventCapacity: Event;
  updateEventPlaylist: Event;
  updateEventSpeaker: EventSpeaker;
  updateFilm: Film;
  updateFilmBranding: Film;
  updateFilmGallery: Film;
  updateGenre: Genre;
  updateLiveStreamPosition: LiveStream;
  updateLiveStreamStage: LiveStream;
  updateOrganizationAvatar: Organization;
  updateOrganizationDisplayName: Organization;
  updatePlaylist: Playlist;
  updateProject: Project;
  updateProjectAssets: Project;
  updateProjectGeoRestrictions: Project;
  updateProjectLicenseRate: Project;
  updateProjectTicketRate: Project;
  updatePromotionCode: PromotionCode;
  updateRateCard: RateCard;
  updateReview: Review;
  updateUser: User;
  upsertFeaturedCollection: FeaturedCollection;
  vmsCompleteMultipartUpload: Scalars['String']['output'];
  vmsCreateAsset: OkPayload;
  vmsCreateConnectAccount: VmsConnectAccount;
  vmsCreateCredit: Credit;
  vmsCreateIndustryEvent: IndustryEvent;
  vmsCreateReview: Review;
  vmsCreateSpeaker: Speaker;
  vmsDeleteAsset: OkPayload;
  vmsDeleteBranding: OkPayload;
  vmsDeleteCredit: OkPayload;
  vmsDeleteIndustryEvent: OkPayload;
  vmsDeleteReview: OkPayload;
  vmsDeleteSpeaker: OkPayload;
  vmsDeleteSubtitle: OkPayload;
  vmsDeleteVideoReference: OkPayload;
  vmsInitVideoUpload: VmsInitVideoUpload;
  vmsListProject: OkPayload;
  vmsPresignedUrls: VmsPresignedUrls;
  vmsPublishProject: OkPayload;
  vmsSaveSubtitle: OkPayload;
  vmsUpdateBranding: Film;
  vmsUpdateCredit: Credit;
  vmsUpdateFilmTrailer: Film;
  vmsUpdateIndustryEvent: IndustryEvent;
  vmsUpdateProjectIntroduction: OkPayload;
  vmsUpdateReview: Review;
  vmsUpdateRightsManagement: Project;
  vmsUpdateSpeaker: Speaker;
};


export type MutationActivateRateCardArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddOrganizationMemberArgs = {
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationApproveEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationAssetUploadPresignedArgs = {
  input: AssetPresignedPostInput;
};


export type MutationAuthenticateArgs = {
  input: LoginInput;
};


export type MutationBanChatMemberArgs = {
  input: BanChatMemberInput;
};


export type MutationCancelEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationConnectStripeAccountArgs = {
  input: ConnectStripeAccountInput;
};


export type MutationCreateCardArgs = {
  source: Scalars['String']['input'];
};


export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput;
};


export type MutationCreateConnectAccountArgs = {
  input: CreateConnectAccountInput;
};


export type MutationCreateConnectAccountLinkArgs = {
  input: CreateConnectAccountLinkInput;
};


export type MutationCreateConnectPersonArgs = {
  input: CreateConnectPersonInput;
};


export type MutationCreateCreditArgs = {
  input: CreateCreditInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateEventSpeakerArgs = {
  input: CreateEventSpeakerInput;
};


export type MutationCreateExternalAccountArgs = {
  input: CreateExternalAccountInput;
};


export type MutationCreateFilmArgs = {
  input: CreateFilmInput;
};


export type MutationCreateFilmAssetArgs = {
  input: CreateFilmAssetInput;
};


export type MutationCreateGenreArgs = {
  input: CreateGenreInput;
};


export type MutationCreateIndustryEventArgs = {
  input: CreateIndustryEventInput;
};


export type MutationCreateLiveStreamArgs = {
  input: CreateLiveStreamInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrganizationInternalArgs = {
  input: CreateOrganizationInternalInput;
};


export type MutationCreatePlaylistArgs = {
  input: CreatePlaylistInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreatePromotionCodeArgs = {
  input: CreatePromotionCodeInput;
};


export type MutationCreateRateCardArgs = {
  input: CreateRateCardInput;
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


export type MutationCreateScreenerArgs = {
  input: CreateScreenerInput;
};


export type MutationCreateSpeakerArgs = {
  input: CreateSpeakerInput;
};


export type MutationCreateVenueAssetArgs = {
  input: CreateVenueAssetInput;
};


export type MutationDeactivateRateCardArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAssetArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteBroadcastParticipantArgs = {
  input: DeleteBroadcastParticipantInput;
};


export type MutationDeleteCardArgs = {
  source: Scalars['String']['input'];
};


export type MutationDeleteChatMessageArgs = {
  input: DeleteChatMessageInput;
};


export type MutationDeleteCollectionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCreditArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteEventBrandingArgs = {
  input: DeleteEventBrandingInput;
};


export type MutationDeleteEventSpeakerArgs = {
  input: DeleteEventSpeakerInput;
};


export type MutationDeleteEventSpeakerAssetArgs = {
  input: DeleteEventSpeakerAssetInput;
};


export type MutationDeleteExternalAccountArgs = {
  input: DeleteExternalAccountInput;
};


export type MutationDeleteGenreArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteIndustryEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOrganizationAvatarArgs = {
  input: DeleteOrganizationAvatarInput;
};


export type MutationDeletePlaylistArgs = {
  input: DeletePlaylistInput;
};


export type MutationDeleteRateCardArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteReviewArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteScreenerArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSpeakerArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVenueAssetArgs = {
  input: DeleteVenueAssetInput;
};


export type MutationDrmTokenArgs = {
  eventId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationDrmTokenConsoleArgs = {
  assetId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationDrmTokenOfflineArgs = {
  eventId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationDrmTokenRentalArgs = {
  input: DrmTokenRentalInput;
};


export type MutationDrmTokenScreenerArgs = {
  accessCode: Scalars['String']['input'];
  screenerId: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationDrmTokenSupportArgs = {
  sessionId: Scalars['String']['input'];
};


export type MutationFmcAbortMultipartUploadArgs = {
  input: FmcAbortMultipartUploadInput;
};


export type MutationFmcCompleteMultipartUploadArgs = {
  input: FmcCompleteMultipartUploadInput;
};


export type MutationFmcCreateAssetArgs = {
  input: FmcCreateAssetInput;
};


export type MutationFmcCreateConnectAccountArgs = {
  input: FmcCreateConnectAccountInput;
};


export type MutationFmcCreateCreditArgs = {
  input: FmcCreateCreditInput;
};


export type MutationFmcCreateFilmProjectArgs = {
  input: FmcCreateFilmProjectInput;
};


export type MutationFmcCreateGalleryAssetArgs = {
  input: FmcCreateGalleryAssetInput;
};


export type MutationFmcCreateIndustryEventArgs = {
  input: FmcCreateIndustryEventInput;
};


export type MutationFmcCreateMultipartUploadArgs = {
  input: FmcCreateMultipartUploadInput;
};


export type MutationFmcCreateReviewArgs = {
  input: FmcCreateReviewInput;
};


export type MutationFmcCreateSpeakerArgs = {
  input: FmcCreateSpeakerInput;
};


export type MutationFmcDeleteAssetArgs = {
  input: FmcDeleteAssetInput;
};


export type MutationFmcDeleteCreditArgs = {
  input: FmcDeleteCreditInput;
};


export type MutationFmcDeleteGalleryAssetArgs = {
  input: FmcDeleteGalleryAssetInput;
};


export type MutationFmcDeleteIndustryEventArgs = {
  input: FmcDeleteIndustryEventInput;
};


export type MutationFmcDeleteReviewArgs = {
  input: FmcDeleteReviewInput;
};


export type MutationFmcDeleteSpeakerArgs = {
  input: FmcDeleteSpeakerInput;
};


export type MutationFmcDeleteSubtitleArgs = {
  input: FmcDeleteSubtitleInput;
};


export type MutationFmcDeleteVideoReferenceArgs = {
  input: FmcDeleteVideoReferenceInput;
};


export type MutationFmcPresignedUrlArgs = {
  input: FmcPresignedUrlInput;
};


export type MutationFmcPublishProjectArgs = {
  input: FmcPublishProjectInput;
};


export type MutationFmcSaveSubtitleArgs = {
  input: FmcSaveSubtitleInput;
};


export type MutationFmcUpdateBasicsArgs = {
  input: FmcUpdateBasicsInput;
};


export type MutationFmcUpdateCreditArgs = {
  input: FmcUpdateCreditInput;
};


export type MutationFmcUpdateFilmTrailerArgs = {
  input: FmcUpdateFilmTrailer;
};


export type MutationFmcUpdateIndustryEventArgs = {
  input: FmcUpdateIndustryEventInput;
};


export type MutationFmcUpdatePricingArgs = {
  input: FmcUpdatePricingInput;
};


export type MutationFmcUpdateReviewArgs = {
  input: FmcUpdateReviewInput;
};


export type MutationFmcUpdateRightsArgs = {
  input: FmcUpdateRightsInput;
};


export type MutationFmcUpdateSpeakerArgs = {
  input: FmcUpdateSpeakerInput;
};


export type MutationHostInquiryArgs = {
  input: HostInquiryInput;
};


export type MutationImportCreditsArgs = {
  input: ImportCreditInput;
};


export type MutationInviteBroadcastParticipantArgs = {
  input: InviteBroadcastParticipantInput;
};


export type MutationOrderPaymentArgs = {
  input: OrderPaymentInput;
};


export type MutationOrganizationCreateConnectAccountArgs = {
  input: OrganizationCreateConnectAccountInput;
};


export type MutationPasswordResetArgs = {
  input: PasswordResetInput;
};


export type MutationPublishEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationPublishProjectArgs = {
  id: Scalars['String']['input'];
};


export type MutationPurchaseRentalArgs = {
  input: PurchaseRentalInput;
};


export type MutationPurchaseTicketsArgs = {
  input: PurchaseTicketsInput;
};


export type MutationRedeemBroadcastInviteArgs = {
  inviteId: Scalars['String']['input'];
};


export type MutationRefundOrderArgs = {
  input: OrderRefundInput;
};


export type MutationRefundTicketArgs = {
  input: RefundTicketInput;
};


export type MutationRemoveOrganizationMemberArgs = {
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationResendBroadcastParticipantInviteArgs = {
  input: ResendBroadcastParticipantInviteInput;
};


export type MutationResetRentalArgs = {
  input: ResetRentalInput;
};


export type MutationResetTicketArgs = {
  ticketId: Scalars['String']['input'];
};


export type MutationRtcTokenArgs = {
  input: AgoraRtcTokenInput;
};


export type MutationSendChatMessageArgs = {
  input: SendChatMessageInput;
};


export type MutationSetExternalAccountAsDefaultArgs = {
  input: SetExternalAccountInput;
};


export type MutationSetFilmBannerArgs = {
  asset: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationSetFilmPosterArgs = {
  asset: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationSetPayoutScheduleArgs = {
  input: SetPayoutScheduleInput;
};


export type MutationSetVideoAudioTracksArgs = {
  input: SetVideoAudioTracksInput;
};


export type MutationSetYouTubeTrailerArgs = {
  id: Scalars['String']['input'];
  trailer: Scalars['String']['input'];
};


export type MutationSignupArgs = {
  input: SignupInput;
};


export type MutationStartBroadcastRecordingArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationStartRentalArgs = {
  input: StartRentalInput;
};


export type MutationStopBroadcastRecordingArgs = {
  eventId: Scalars['String']['input'];
};


export type MutationTicketStartRentalArgs = {
  ticketId: Scalars['String']['input'];
};


export type MutationTriggerPasswordResetArgs = {
  input: TriggerPasswordResetInput;
};


export type MutationUnpublishEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationUnpublishProjectArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput;
};


export type MutationUpdateBroadcastParticipantArgs = {
  input: UpdateBroadcastParticipantInput;
};


export type MutationUpdateBroadcastRecordableArgs = {
  input: UpdateBroadcastRecordableInput;
};


export type MutationUpdateBroadcastStatusArgs = {
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
};


export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateEventBrandingArgs = {
  input: UpdateEventBrandingInput;
};


export type MutationUpdateEventCapacityArgs = {
  input: UpdateEventCapacityInput;
};


export type MutationUpdateEventPlaylistArgs = {
  input: UpdateEventPlaylistInput;
};


export type MutationUpdateEventSpeakerArgs = {
  input: UpdateEventSpeakerInput;
};


export type MutationUpdateFilmArgs = {
  input: UpdateFilmInput;
};


export type MutationUpdateFilmBrandingArgs = {
  input: UpdateFilmBrandingInput;
};


export type MutationUpdateFilmGalleryArgs = {
  input: UpdateGalleryInput;
};


export type MutationUpdateGenreArgs = {
  input: UpdateGenreInput;
};


export type MutationUpdateLiveStreamPositionArgs = {
  id: Scalars['String']['input'];
  position: Scalars['String']['input'];
};


export type MutationUpdateLiveStreamStageArgs = {
  id: Scalars['String']['input'];
  stage: Scalars['Boolean']['input'];
};


export type MutationUpdateOrganizationAvatarArgs = {
  input: UpdateOrganizationAvatarInput;
};


export type MutationUpdateOrganizationDisplayNameArgs = {
  input: UpdateOrganizationDisplayNameInput;
};


export type MutationUpdatePlaylistArgs = {
  input: UpdatePlaylistInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};


export type MutationUpdateProjectAssetsArgs = {
  input: UpdateProjectAssetsInput;
};


export type MutationUpdateProjectGeoRestrictionsArgs = {
  input: UpdateProjectGeoRestrictionsInput;
};


export type MutationUpdateProjectLicenseRateArgs = {
  input: UpdateProjectLicenseRateInput;
};


export type MutationUpdateProjectTicketRateArgs = {
  input: UpdateProjectTicketRateInput;
};


export type MutationUpdatePromotionCodeArgs = {
  input: UpdatePromotionCodeInput;
};


export type MutationUpdateRateCardArgs = {
  input: UpdateRateCardInput;
};


export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpsertFeaturedCollectionArgs = {
  input: UpsertFeaturedCollectionInput;
};


export type MutationVmsCompleteMultipartUploadArgs = {
  input: VmsCompleteMultipartUploadInput;
};


export type MutationVmsCreateAssetArgs = {
  input: VmsCreateAssetInput;
};


export type MutationVmsCreateConnectAccountArgs = {
  input: VmsCreateConnectAccountInput;
};


export type MutationVmsCreateCreditArgs = {
  input: VmsCreateCreditInput;
};


export type MutationVmsCreateIndustryEventArgs = {
  input: VmsCreateIndustryEventInput;
};


export type MutationVmsCreateReviewArgs = {
  input: VmsCreateReviewInput;
};


export type MutationVmsCreateSpeakerArgs = {
  input: VmsCreateSpeakerInput;
};


export type MutationVmsDeleteAssetArgs = {
  input: VmsDeleteAssetInput;
};


export type MutationVmsDeleteBrandingArgs = {
  input: VmsDeleteBrandingInput;
};


export type MutationVmsDeleteCreditArgs = {
  input: VmsDeleteCreditInput;
};


export type MutationVmsDeleteIndustryEventArgs = {
  input: VmsDeleteIndustryEventInput;
};


export type MutationVmsDeleteReviewArgs = {
  input: VmsDeleteReviewInput;
};


export type MutationVmsDeleteSpeakerArgs = {
  input: VmsDeleteSpeakerInput;
};


export type MutationVmsDeleteSubtitleArgs = {
  input: VmsDeleteSubtitleInput;
};


export type MutationVmsDeleteVideoReferenceArgs = {
  input: VmsDeleteVideoReferenceInput;
};


export type MutationVmsInitVideoUploadArgs = {
  input: VmsInitVideoUploadInput;
};


export type MutationVmsListProjectArgs = {
  input: VmsListProjectInput;
};


export type MutationVmsPresignedUrlsArgs = {
  input: VmsPresignedUrlsInput;
};


export type MutationVmsPublishProjectArgs = {
  input: VmsPublishProjectInput;
};


export type MutationVmsSaveSubtitleArgs = {
  input: VmsSaveSubtitleInput;
};


export type MutationVmsUpdateBrandingArgs = {
  input: VmsUpdateBrandingInput;
};


export type MutationVmsUpdateCreditArgs = {
  input: VmsUpdateCreditInput;
};


export type MutationVmsUpdateFilmTrailerArgs = {
  input: VmsUpdateFilmTrailerInput;
};


export type MutationVmsUpdateIndustryEventArgs = {
  input: VmsUpdateIndustryEventInput;
};


export type MutationVmsUpdateProjectIntroductionArgs = {
  input: VmsUpdateProjectIntroductionInput;
};


export type MutationVmsUpdateReviewArgs = {
  input: VmsUpdateReviewInput;
};


export type MutationVmsUpdateRightsManagementArgs = {
  input: VmsUpdateRightsManagementInput;
};


export type MutationVmsUpdateSpeakerArgs = {
  input: VmsUpdateSpeakerInput;
};

export type OkPayload = {
  __typename?: 'OkPayload';
  ok: Scalars['Boolean']['output'];
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  discount: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  items: Array<OrderItem>;
  organizerAmount: Scalars['Float']['output'];
  ownerAmount: Scalars['Float']['output'];
  paymentFee: Scalars['Float']['output'];
  payments?: Maybe<Array<Payment>>;
  platformFee: Scalars['Float']['output'];
  status: OrderStatus;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderPaymentInput = {
  orderId: Scalars['String']['input'];
  source: Scalars['String']['input'];
};

export type OrderRefundInput = {
  eventId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export enum OrderStatus {
  Declined = 'DECLINED',
  Fulfilled = 'FULFILLED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

export type Organization = {
  __typename?: 'Organization';
  avatar?: Maybe<Asset>;
  connectAccount?: Maybe<ConnectAccount>;
  createdAt: Scalars['DateTime']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isMember: Scalars['Boolean']['output'];
  isOwner: Scalars['Boolean']['output'];
  members?: Maybe<Array<User>>;
  name: Scalars['String']['output'];
  owner?: Maybe<User>;
  projectCount: Scalars['Float']['output'];
  stripeConnectId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationBalanceTransaction = {
  __typename?: 'OrganizationBalanceTransaction';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  film: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type OrganizationCreateConnectAccountInput = {
  country: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  refreshUrl: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};

export type OrganizationMetrics = {
  __typename?: 'OrganizationMetrics';
  attendees: Scalars['Float']['output'];
  revenue: Scalars['Float']['output'];
  totalEvents: Scalars['Float']['output'];
};

export type OrganizationPayout = {
  __typename?: 'OrganizationPayout';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export type OrganizationProjectMetrics = {
  __typename?: 'OrganizationProjectMetrics';
  eventCount: Scalars['Float']['output'];
  licenseGross: Scalars['Float']['output'];
  ticketCount: Scalars['Float']['output'];
  ticketGross: Scalars['Float']['output'];
};

export type PaginatedChatMessages = {
  __typename?: 'PaginatedChatMessages';
  afterCursor?: Maybe<Scalars['String']['output']>;
  beforeCursor?: Maybe<Scalars['String']['output']>;
  items: Array<ChatMessage>;
};

export type PaginatedCollections = {
  __typename?: 'PaginatedCollections';
  items: Array<Collection>;
  total: Scalars['Int']['output'];
};

export type PaginatedEvents = {
  __typename?: 'PaginatedEvents';
  items: Array<Event>;
  total: Scalars['Int']['output'];
};

export type PaginatedFilms = {
  __typename?: 'PaginatedFilms';
  items: Array<Film>;
  total: Scalars['Int']['output'];
};

export type PaginatedMetricEvents = {
  __typename?: 'PaginatedMetricEvents';
  items: Array<Event>;
  total: Scalars['Int']['output'];
};

export type PaginatedOrganizations = {
  __typename?: 'PaginatedOrganizations';
  items: Array<Organization>;
  total: Scalars['Int']['output'];
};

export type PaginatedProjects = {
  __typename?: 'PaginatedProjects';
  items: Array<Project>;
  total: Scalars['Int']['output'];
};

export type PaginatedPromotionCodes = {
  __typename?: 'PaginatedPromotionCodes';
  items: Array<PromotionCode>;
  total: Scalars['Int']['output'];
};

export type PaginatedRentals = {
  __typename?: 'PaginatedRentals';
  items: Array<Rental>;
  total: Scalars['Int']['output'];
};

export type PaginatedTicketOrders = {
  __typename?: 'PaginatedTicketOrders';
  items: Array<TicketOrder>;
  total: Scalars['Int']['output'];
};

export type PaginatedTmdbResponse = {
  __typename?: 'PaginatedTmdbResponse';
  items: Array<TmdbResponse>;
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalResults: Scalars['Int']['output'];
};

export type PaginatedTransfers = {
  __typename?: 'PaginatedTransfers';
  items: Array<Transfer>;
  total: Scalars['Int']['output'];
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  items: Array<User>;
  total: Scalars['Int']['output'];
};

export type PaletteColor = {
  __typename?: 'PaletteColor';
  blue?: Maybe<Scalars['Float']['output']>;
  green?: Maybe<Scalars['Float']['output']>;
  hex?: Maybe<Scalars['String']['output']>;
  red?: Maybe<Scalars['Float']['output']>;
};

export type PartInput = {
  ETag: Scalars['String']['input'];
  partNumber: Scalars['Float']['input'];
};

export enum ParticipantStatus {
  Host = 'HOST',
  Invited = 'INVITED',
  Participant = 'PARTICIPANT'
}

export type PasswordResetInput = {
  password: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Int']['output'];
  charge?: Maybe<Charge>;
  chargeId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: PaymentStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum PaymentStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeded = 'SUCCEDED'
}

export type Person = {
  __typename?: 'Person';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tmdbId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type Playlist = {
  __typename?: 'Playlist';
  bumperIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  default: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  drmAssetId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  playlist: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  subtitleIds: Array<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  videoIds: Array<Scalars['String']['output']>;
};

export type PlaylistHasEvents = {
  __typename?: 'PlaylistHasEvents';
  hasEvents: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  allowOnDemand: Scalars['Boolean']['output'];
  allowPhysical: Scalars['Boolean']['output'];
  allowRental: Scalars['Boolean']['output'];
  allowVirtual: Scalars['Boolean']['output'];
  approval: Scalars['Boolean']['output'];
  availabilityEnd?: Maybe<Scalars['DateTime']['output']>;
  availabilityStart?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dashUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  drmAssetId?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  film: Film;
  flexTicketEnabled: Scalars['Boolean']['output'];
  geoAllowRestrictions: Array<Scalars['String']['output']>;
  geoBlacklist?: Maybe<Array<Scalars['String']['output']>>;
  geoDenyRestrictions: Array<Scalars['String']['output']>;
  geoRestrictions?: Maybe<Array<Scalars['String']['output']>>;
  geoWhitelist?: Maybe<Array<Scalars['String']['output']>>;
  hasAssets: Scalars['Boolean']['output'];
  hasV2Assets: Scalars['Boolean']['output'];
  hlsUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  licenseOptional: Scalars['Boolean']['output'];
  licenseRate?: Maybe<ProjectRate>;
  listed: Scalars['Boolean']['output'];
  organization?: Maybe<Organization>;
  playlistEnabled: Scalars['Boolean']['output'];
  price: Scalars['Int']['output'];
  priceEnabled: Scalars['Boolean']['output'];
  published: Scalars['Boolean']['output'];
  rateCards: Array<RateCard>;
  rentalPrice: Scalars['Int']['output'];
  rentalRate: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
  ticketMax: Scalars['Int']['output'];
  ticketMin: Scalars['Int']['output'];
  ticketRate?: Maybe<ProjectRate>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  visualWatermark: Scalars['Boolean']['output'];
  vmsEnabled: Scalars['Boolean']['output'];
};

export type ProjectIntroductionFilmGenreInput = {
  id: Scalars['String']['input'];
};

export type ProjectPlaylist = {
  __typename?: 'ProjectPlaylist';
  bumperIds: Array<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  playlist: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  videoIds: Array<Scalars['String']['output']>;
};

export type ProjectRate = {
  __typename?: 'ProjectRate';
  organizer: Scalars['Int']['output'];
  owner: Scalars['Int']['output'];
  platform: Scalars['Int']['output'];
};

export type ProjectRating = {
  __typename?: 'ProjectRating';
  capacity: Scalars['Int']['output'];
  capacityOverrage: Scalars['Int']['output'];
  discount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  licenseFee: Scalars['Int']['output'];
  overrage: Scalars['Int']['output'];
  subtotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unitAmount: Scalars['Int']['output'];
};

export type PromotionCode = {
  __typename?: 'PromotionCode';
  amountOff?: Maybe<Scalars['Int']['output']>;
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  percentOff?: Maybe<Scalars['Int']['output']>;
  resourceId: Scalars['String']['output'];
  resourceType: PromotionCodeResourceType;
  type: PromotionCodeType;
  updatedAt: Scalars['DateTime']['output'];
  usage: Scalars['Int']['output'];
  usageLimit: Scalars['Int']['output'];
};

export enum PromotionCodeResourceType {
  Event = 'EVENT',
  Project = 'PROJECT',
  Rental = 'RENTAL'
}

export enum PromotionCodeType {
  Amount = 'AMOUNT',
  Percent = 'PERCENT'
}

export type PurchaseRentalInput = {
  contactable?: InputMaybe<Scalars['Boolean']['input']>;
  payment?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  promotionCode?: InputMaybe<Scalars['String']['input']>;
};

export type PurchaseTicketsInput = {
  attendees: Array<AttendeeInput>;
  contactable?: InputMaybe<Scalars['Boolean']['input']>;
  contactableByFilmmaker?: InputMaybe<Scalars['Boolean']['input']>;
  eventId: Scalars['String']['input'];
  flexPrice?: InputMaybe<Scalars['Int']['input']>;
  payment?: InputMaybe<Scalars['String']['input']>;
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  availableGenres: Array<Genre>;
  bannerProject: Project;
  cards: Array<Card>;
  cardsByUser: Array<Card>;
  changeCapacityRate: ChangeCapacityRate;
  chatChannel: ChatChannel;
  chatChannelByBroadcastId: ChatChannel;
  chatChannelByEventId: ChatChannel;
  chatMemberProfile: ChatMember;
  chatMessages: PaginatedChatMessages;
  collection: Collection;
  collections: PaginatedCollections;
  downloadAsset: AssetDownload;
  emailAvailable: OkPayload;
  event: Event;
  eventLiveStream: LiveStream;
  eventSpeaker: EventSpeaker;
  eventSpeakers: Array<EventSpeaker>;
  eventTickets: PaginatedTicketOrders;
  events: PaginatedEvents;
  featuredProjects: Project;
  film: Film;
  filmCredits: Array<Credit>;
  filmCreditsTmdb: Array<TmdbCredit>;
  filmSpeakers: Array<Speaker>;
  films: PaginatedFilms;
  findBroadcastByEvent: Broadcast;
  findBroadcastInvite: BroadcastInviteResponse;
  findBroadcastRecording: BroadcastRecording;
  findCollectionById: Collection;
  findCollectionsByIds: Array<Collection>;
  findCreatePlaylist: CreatePlaylist;
  findEventBySlug: Event;
  findEvents: PaginatedEvents;
  findMyTicketsByEvent: Array<Ticket>;
  findProjectBySlug: Project;
  findProjects: PaginatedProjects;
  findProjectsByOrganization: PaginatedProjects;
  findTicketOrdersByEvent: PaginatedTicketOrders;
  findTicketOrdersByEventOrganization: PaginatedTicketOrders;
  findTicketOrdersByEventProjectOrganization: PaginatedTicketOrders;
  fmcConnectAccountByProject: FmcConnectAccount;
  fmcListUploadParts: Array<FmcUploadPart>;
  fmcProject: Project;
  fmcProjectVideo: FmcVideo;
  fmcSubtitleLanguageExists: OkPayload;
  genres: Array<Genre>;
  hasEventTicket: OkPayload;
  hasRentalByProjectSlug: Scalars['Boolean']['output'];
  industryEvents: Array<IndustryEvent>;
  liveStream: LiveStream;
  me: User;
  myEvents: PaginatedEvents;
  myOfflineEvents: PaginatedEvents;
  myOrganizations: Array<Organization>;
  myRentals: PaginatedRentals;
  myTickets: PaginatedTicketOrders;
  order: Order;
  organization: Organization;
  organizationBalanceTransactions: Array<OrganizationBalanceTransaction>;
  organizationConnectAccount: ConnectAccount;
  organizationMetrics: OrganizationMetrics;
  organizationNameAvailable: Scalars['Boolean']['output'];
  organizationPayouts: Array<OrganizationPayout>;
  organizationProjectsEvents: PaginatedMetricEvents;
  organizationProjectsMetrics: OrganizationProjectMetrics;
  organizations: PaginatedOrganizations;
  playlistEvents: Array<Event>;
  playlistHasEvents: PlaylistHasEvents;
  popularTmdb: PaginatedTmdbResponse;
  project: Project;
  projectGeoRestriction: GeoRestriction;
  projectPlaylists: Array<ProjectPlaylist>;
  projectRateCards: Array<RateCard>;
  projectRating: ProjectRating;
  projectScreeners: Array<Screener>;
  projects: PaginatedProjects;
  promotionCode: PromotionCode;
  promotionCodes: PaginatedPromotionCodes;
  rateCardRate: RateCardRate;
  rental: Rental;
  rentalByProjectSlug: Rental;
  rentalRating: RentalRating;
  rentals: PaginatedRentals;
  review: Review;
  reviews: Array<Review>;
  screener: Screener;
  searchTmdb: PaginatedTmdbResponse;
  searchTmdbPerson: PaginatedTmdbResponse;
  stripeVerifyPromotionCode: OkPayload;
  ticketOrdersByUser: PaginatedTicketOrders;
  ticketRating: TicketRating;
  timezone: Scalars['String']['output'];
  transfer: Transfer;
  transfers: PaginatedTransfers;
  triggerEventTransfersProducer: OkPayload;
  triggerTransferProducerProcessor: OkPayload;
  user: User;
  users: PaginatedUsers;
  vmsFindConnectAccount: VmsConnectAccount;
  vmsFindProjectBySlug: VmsProject;
  vmsFindProjectsByOrganization: VmsPaginatedProjects;
  vmsSubtitleLanguageExists: OkPayload;
  vodAssets: VodAssets;
  watchScreener: Screener;
};


export type QueryCardsByUserArgs = {
  userId: Scalars['String']['input'];
};


export type QueryChangeCapacityRateArgs = {
  capacity: Scalars['Int']['input'];
  eventId: Scalars['String']['input'];
};


export type QueryChatChannelArgs = {
  channelId: Scalars['String']['input'];
};


export type QueryChatChannelByBroadcastIdArgs = {
  broadcastId: Scalars['String']['input'];
};


export type QueryChatChannelByEventIdArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryChatMemberProfileArgs = {
  channelId: Scalars['String']['input'];
};


export type QueryChatMessagesArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  beforeCursor?: InputMaybe<Scalars['String']['input']>;
  channelId: Scalars['String']['input'];
};


export type QueryCollectionArgs = {
  id: Scalars['String']['input'];
};


export type QueryCollectionsArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDownloadAssetArgs = {
  id: Scalars['String']['input'];
};


export type QueryEmailAvailableArgs = {
  email: Scalars['String']['input'];
};


export type QueryEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryEventLiveStreamArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryEventSpeakerArgs = {
  id: Scalars['String']['input'];
};


export type QueryEventSpeakersArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryEventTicketsArgs = {
  eventId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryEventsArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EventStatus>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFilmArgs = {
  id: Scalars['String']['input'];
};


export type QueryFilmCreditsArgs = {
  filmId: Scalars['String']['input'];
};


export type QueryFilmCreditsTmdbArgs = {
  tmdbId: Scalars['String']['input'];
};


export type QueryFilmSpeakersArgs = {
  filmId: Scalars['String']['input'];
};


export type QueryFilmsArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindBroadcastByEventArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryFindBroadcastInviteArgs = {
  inviteId: Scalars['String']['input'];
};


export type QueryFindBroadcastRecordingArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryFindCollectionByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryFindCollectionsByIdsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryFindCreatePlaylistArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryFindEventBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryFindEventsArgs = {
  genre?: InputMaybe<Scalars['String']['input']>;
  project?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<EventType>>;
};


export type QueryFindMyTicketsByEventArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryFindProjectBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryFindProjectsArgs = {
  genre?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindProjectsByOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindTicketOrdersByEventArgs = {
  eventId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindTicketOrdersByEventOrganizationArgs = {
  eventId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindTicketOrdersByEventProjectOrganizationArgs = {
  eventId: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFmcConnectAccountByProjectArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryFmcListUploadPartsArgs = {
  fileId: Scalars['String']['input'];
  fileKey: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


export type QueryFmcProjectArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryFmcProjectVideoArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryFmcSubtitleLanguageExistsArgs = {
  hasCC: Scalars['Boolean']['input'];
  language: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


export type QueryHasEventTicketArgs = {
  eventId: Scalars['String']['input'];
};


export type QueryHasRentalByProjectSlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryIndustryEventsArgs = {
  filmId: Scalars['String']['input'];
};


export type QueryLiveStreamArgs = {
  id: Scalars['String']['input'];
};


export type QueryMyEventsArgs = {
  afterEnd?: InputMaybe<Scalars['DateTime']['input']>;
  afterStart?: InputMaybe<Scalars['DateTime']['input']>;
  beforeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  beforeStart?: InputMaybe<Scalars['DateTime']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  take?: Scalars['Int']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMyOfflineEventsArgs = {
  afterEnd?: InputMaybe<Scalars['DateTime']['input']>;
  afterStart?: InputMaybe<Scalars['DateTime']['input']>;
  beforeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  beforeStart?: InputMaybe<Scalars['DateTime']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  take?: Scalars['Int']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMyRentalsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMyTicketsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrderArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrganizationBalanceTransactionsArgs = {
  id: Scalars['String']['input'];
  payoutId: Scalars['String']['input'];
};


export type QueryOrganizationConnectAccountArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryOrganizationNameAvailableArgs = {
  name: Scalars['String']['input'];
};


export type QueryOrganizationPayoutsArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrganizationProjectsEventsArgs = {
  organizationId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationProjectsMetricsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryOrganizationsArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPlaylistEventsArgs = {
  playlistId: Scalars['String']['input'];
};


export type QueryPlaylistHasEventsArgs = {
  playlistId: Scalars['String']['input'];
};


export type QueryPopularTmdbArgs = {
  page?: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  releaseType?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProjectArgs = {
  id: Scalars['String']['input'];
};


export type QueryProjectGeoRestrictionArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryProjectPlaylistsArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryProjectRateCardsArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryProjectRatingArgs = {
  capacity: Scalars['Int']['input'];
  projectId: Scalars['String']['input'];
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  rateCardId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProjectScreenersArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryProjectsArgs = {
  genre?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPromotionCodeArgs = {
  id: Scalars['String']['input'];
};


export type QueryPromotionCodesArgs = {
  resourceId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRateCardRateArgs = {
  capacity: Scalars['Int']['input'];
  rateCardId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRentalArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentalByProjectSlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryRentalRatingArgs = {
  projectId: Scalars['String']['input'];
  promotionCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRentalsArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReviewArgs = {
  id: Scalars['String']['input'];
};


export type QueryReviewsArgs = {
  filmId: Scalars['String']['input'];
};


export type QueryScreenerArgs = {
  id: Scalars['String']['input'];
};


export type QuerySearchTmdbArgs = {
  page?: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  releaseType?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchTmdbPersonArgs = {
  page?: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  releaseType?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStripeVerifyPromotionCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryTicketOrdersByUserArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryTicketRatingArgs = {
  eventId: Scalars['String']['input'];
  promotionCode?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};


export type QueryTimezoneArgs = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};


export type QueryTransferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTransfersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};


export type QueryVmsFindConnectAccountArgs = {
  organizationId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  projectSlug: Scalars['String']['input'];
};


export type QueryVmsFindProjectBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryVmsFindProjectsByOrganizationArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryVmsSubtitleLanguageExistsArgs = {
  hasCC: Scalars['Boolean']['input'];
  language: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


export type QueryVodAssetsArgs = {
  projectId: Scalars['String']['input'];
};


export type QueryWatchScreenerArgs = {
  accessCode: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type RateCard = {
  __typename?: 'RateCard';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items: Array<RateCardItem>;
  name: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  unitAmount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RateCardItem = {
  __typename?: 'RateCardItem';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  end: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  start: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type RateCardLineInput = {
  amount: Scalars['Int']['input'];
  end: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['Int']['input'];
};

export type RateCardRate = {
  __typename?: 'RateCardRate';
  amount: Scalars['Int']['output'];
  capacity: Scalars['Int']['output'];
  capacityOverrage: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  overrage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unitAmount: Scalars['Int']['output'];
};

export type RefundTicketInput = {
  eventId: Scalars['String']['input'];
  ticketOrderId: Scalars['String']['input'];
};

export type Rental = {
  __typename?: 'Rental';
  createdAt: Scalars['DateTime']['output'];
  dashUrl?: Maybe<Scalars['String']['output']>;
  drmAssetId?: Maybe<Scalars['String']['output']>;
  end: Scalars['DateTime']['output'];
  hlsUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  order?: Maybe<Order>;
  project: Project;
  start?: Maybe<Scalars['DateTime']['output']>;
  status: RentalStatus;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type RentalRating = {
  __typename?: 'RentalRating';
  currency: Scalars['String']['output'];
  discount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  subtotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum RentalStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export type ResendBroadcastParticipantInviteInput = {
  broadcastId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
};

export type ResetRentalInput = {
  rentalId: Scalars['String']['input'];
};

export type Review = {
  __typename?: 'Review';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  source: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export enum Roles {
  Manager = 'MANAGER',
  Standard = 'STANDARD'
}

export type Screener = {
  __typename?: 'Screener';
  accessCode: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dashUrl?: Maybe<Scalars['String']['output']>;
  drmAssetId?: Maybe<Scalars['String']['output']>;
  end: Scalars['DateTime']['output'];
  hlsUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  playlistDrmAssetId?: Maybe<Scalars['String']['output']>;
  playlistId?: Maybe<Scalars['String']['output']>;
  project: Project;
  start: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usePlaylist?: Maybe<Scalars['Boolean']['output']>;
};

export type SendChatMessageInput = {
  channelId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type Sequence = {
  __typename?: 'Sequence';
  clips: ClipSegment;
};

export type SetExternalAccountInput = {
  externalAccountId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type SetPayoutScheduleInput = {
  interval: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type SetVideoAudioTracksInput = {
  audioTracks: Scalars['String']['input'];
  createdAt: Scalars['String']['input'];
  videoIngestId: Scalars['String']['input'];
};

export type SignupInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Speaker = {
  __typename?: 'Speaker';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  film: Film;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  physical: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  virtual: Scalars['Boolean']['output'];
};

export type StartRentalInput = {
  rentalId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  broadcastUpdated: Broadcast;
  chatMemberBanned: ChatMember;
  chatMemberUnbanned: ChatMember;
  chatMessageAdded: ChatMessage;
  chatMessageDeleted: ChatMessage;
  eventUpdated: Event;
  liveStreamUpdated: LiveStream;
};


export type SubscriptionBroadcastUpdatedArgs = {
  broadcastId: Scalars['String']['input'];
};


export type SubscriptionChatMemberBannedArgs = {
  channelId: Scalars['String']['input'];
};


export type SubscriptionChatMemberUnbannedArgs = {
  channelId: Scalars['String']['input'];
};


export type SubscriptionChatMessageAddedArgs = {
  channelId: Scalars['String']['input'];
};


export type SubscriptionChatMessageDeletedArgs = {
  channelId: Scalars['String']['input'];
};


export type SubscriptionEventUpdatedArgs = {
  eventId: Scalars['String']['input'];
};


export type SubscriptionLiveStreamUpdatedArgs = {
  liveStreamId: Scalars['String']['input'];
};

export type Subtitle = {
  __typename?: 'Subtitle';
  hasCC?: Maybe<Scalars['Boolean']['output']>;
  key: Scalars['String']['output'];
  language: Scalars['String']['output'];
  originalfilename: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Ticket = {
  __typename?: 'Ticket';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  end?: Maybe<Scalars['DateTime']['output']>;
  event: Event;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  order: TicketOrder;
  start?: Maybe<Scalars['DateTime']['output']>;
  ticketClass: TicketClass;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type TicketClass = {
  __typename?: 'TicketClass';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TicketOrder = {
  __typename?: 'TicketOrder';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  discount: Scalars['Float']['output'];
  event?: Maybe<Event>;
  id: Scalars['ID']['output'];
  items: Array<OrderItem>;
  organizerAmount: Scalars['Float']['output'];
  ownerAmount: Scalars['Float']['output'];
  paymentFee: Scalars['Float']['output'];
  payments?: Maybe<Array<Payment>>;
  platformFee: Scalars['Float']['output'];
  status: OrderStatus;
  tickets: Array<Ticket>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type TicketRating = {
  __typename?: 'TicketRating';
  currency: Scalars['String']['output'];
  discount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  subtotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TmdbCredit = {
  __typename?: 'TmdbCredit';
  character?: Maybe<Scalars['String']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  job?: Maybe<Scalars['String']['output']>;
  knownForDepartment: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Float']['output']>;
  profilePath?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type TmdbResponse = {
  __typename?: 'TmdbResponse';
  asset: Array<TmdbResponseAsset>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TmdbResponseAsset = {
  __typename?: 'TmdbResponseAsset';
  description: Scalars['String']['output'];
  link: Scalars['String']['output'];
};

export type Transfer = {
  __typename?: 'Transfer';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  organization: Organization;
  status: TransferStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum TransferStatus {
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type TriggerPasswordResetInput = {
  email: Scalars['String']['input'];
};

export type UpdateAssetInput = {
  id: Scalars['String']['input'];
  tags: Array<Scalars['String']['input']>;
};

export type UpdateBroadcastParticipantInput = {
  broadcastId: Scalars['String']['input'];
  participantId: Scalars['String']['input'];
  status: Scalars['String']['input'];
};

export type UpdateBroadcastRecordableInput = {
  id: Scalars['String']['input'];
  recordable: Scalars['Boolean']['input'];
};

export type UpdateCollectionInput = {
  id: Scalars['String']['input'];
  items: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type UpdateEventBrandingInput = {
  eventId: Scalars['String']['input'];
  file?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<FocalPointInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEventCapacityInput = {
  capacity: Scalars['Int']['input'];
  eventId: Scalars['String']['input'];
  payment?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEventInput = {
  capacity: Scalars['Int']['input'];
  chatEnabled?: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['DateTime']['input'];
  featureDescription?: InputMaybe<Scalars['String']['input']>;
  hostedByOverride?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  listed: Scalars['Boolean']['input'];
  organizationName: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  priceLabel?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['DateTime']['input'];
  streamUrl?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  title: Scalars['String']['input'];
  venue?: InputMaybe<UpdateVenueInput>;
};

export type UpdateEventPlaylistInput = {
  id: Scalars['String']['input'];
  playlistId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEventSpeakerInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  focalPoint?: InputMaybe<FocalPointInput>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateFilmBrandingInput = {
  file?: InputMaybe<Scalars['String']['input']>;
  filmId: Scalars['String']['input'];
  focalPoint?: InputMaybe<FocalPointInput>;
  name: Scalars['String']['input'];
};

export type UpdateFilmInput = {
  description: Scalars['String']['input'];
  genre: Scalars['String']['input'];
  genres?: InputMaybe<Array<FilmGenreInput>>;
  id: Scalars['String']['input'];
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  runtime?: InputMaybe<Scalars['Int']['input']>;
  tagline: Scalars['String']['input'];
  title: Scalars['String']['input'];
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateGalleryInput = {
  filmId: Scalars['String']['input'];
  items: Array<GalleryInput>;
};

export type UpdateGenreInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateOrganizationAvatarInput = {
  key: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type UpdateOrganizationDisplayNameInput = {
  displayName: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type UpdatePlaylistInput = {
  bumperIds: Array<Scalars['String']['input']>;
  changeDefault: Scalars['Boolean']['input'];
  default: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  subtitleIds: Array<Scalars['String']['input']>;
  videoId: Scalars['String']['input'];
};

export type UpdateProjectAssetsInput = {
  dashUrl: Scalars['String']['input'];
  downloadUrl: Scalars['String']['input'];
  drmAssetId: Scalars['String']['input'];
  hlsUrl: Scalars['String']['input'];
  id: Scalars['String']['input'];
  visualWatermark?: Scalars['Boolean']['input'];
};

export type UpdateProjectGeoRestrictionsInput = {
  geoBlacklist: Array<Scalars['String']['input']>;
  geoWhitelist: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type UpdateProjectInput = {
  allowOnDemand: Scalars['Boolean']['input'];
  allowPhysical: Scalars['Boolean']['input'];
  allowRental: Scalars['Boolean']['input'];
  allowVirtual: Scalars['Boolean']['input'];
  availabilityEnd?: InputMaybe<Scalars['DateTime']['input']>;
  availabilityStart?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  end?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  licenseOptional?: InputMaybe<Scalars['Boolean']['input']>;
  listed: Scalars['Boolean']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  playlistEnabled: Scalars['Boolean']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  priceEnabled: Scalars['Boolean']['input'];
  rentalPrice?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  ticketMax?: InputMaybe<Scalars['Int']['input']>;
  ticketMin?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  vmsEnabled?: Scalars['Boolean']['input'];
};

export type UpdateProjectLicenseRateInput = {
  id: Scalars['String']['input'];
  owner: Scalars['Int']['input'];
  platform: Scalars['Int']['input'];
};

export type UpdateProjectTicketRateInput = {
  id: Scalars['String']['input'];
  organizer: Scalars['Int']['input'];
  owner: Scalars['Int']['input'];
  platform: Scalars['Int']['input'];
};

export type UpdatePromotionCodeInput = {
  amountOff?: InputMaybe<Scalars['Int']['input']>;
  code: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  percentOff?: InputMaybe<Scalars['Int']['input']>;
  usageLimit: Scalars['Int']['input'];
};

export type UpdateRateCardInput = {
  id: Scalars['String']['input'];
  items: Array<RateCardLineInput>;
  name: Scalars['String']['input'];
  unitAmount: Scalars['Int']['input'];
};

export type UpdateReviewInput = {
  content: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  source: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type UpdateUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVenueInput = {
  attributes: Array<VenueAttributeInput>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  placeId: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertFeaturedCollectionInput = {
  items: Array<Scalars['String']['input']>;
  type: FeaturedCollectionType;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  cards?: Maybe<Array<Card>>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  eventCount: Scalars['Float']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organizations: Array<Organization>;
  phone?: Maybe<Scalars['String']['output']>;
  rentalCount: Scalars['Float']['output'];
  roles: Array<Roles>;
  stripeId?: Maybe<Scalars['String']['output']>;
  ticketCount: Scalars['Float']['output'];
  upcomingEventCount: Scalars['Int']['output'];
  upcomingTicketCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vmsEnabled: Scalars['Boolean']['output'];
};

export type Venue = {
  __typename?: 'Venue';
  attributes?: Maybe<Array<VenueAttribute>>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  event: Event;
  id: Scalars['ID']['output'];
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  photos?: Maybe<Array<Asset>>;
  placeId?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street1: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type VenueAttribute = {
  __typename?: 'VenueAttribute';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type VenueAttributeInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Video = {
  __typename?: 'Video';
  createdAt: Scalars['String']['output'];
  drmAssetId: Scalars['String']['output'];
  duration: Scalars['Float']['output'];
  filmId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  processedVideos: Array<Scalars['String']['output']>;
  srcVideo?: Maybe<Scalars['String']['output']>;
};

export type VmsAccountLink = {
  __typename?: 'VmsAccountLink';
  created: Scalars['Float']['output'];
  expiresAt: Scalars['Float']['output'];
  object: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type VmsCompleteMultipartUploadInput = {
  fileId: Scalars['String']['input'];
  fileKey: Scalars['String']['input'];
  originalFilename: Scalars['String']['input'];
  parts: Array<PartInput>;
  projectId: Scalars['String']['input'];
};

export type VmsConnectAccount = {
  __typename?: 'VmsConnectAccount';
  accountLink: VmsAccountLink;
  id: Scalars['String']['output'];
  requirements: VmsConnectRequirements;
  type: Scalars['String']['output'];
};

export type VmsConnectError = {
  __typename?: 'VmsConnectError';
  code: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  requirement: Scalars['String']['output'];
};

export type VmsConnectRequirements = {
  __typename?: 'VmsConnectRequirements';
  currentlyDue: Array<Scalars['String']['output']>;
  errors: Array<VmsConnectError>;
  eventuallyDue: Array<Scalars['String']['output']>;
  pastDue: Array<Scalars['String']['output']>;
  pendingVerification: Array<Scalars['String']['output']>;
};

export type VmsCreateAssetInput = {
  isBanner?: InputMaybe<Scalars['Boolean']['input']>;
  isGallery?: InputMaybe<Scalars['Boolean']['input']>;
  isPoster?: InputMaybe<Scalars['Boolean']['input']>;
  isPromotion?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsCreateConnectAccountInput = {
  country: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  projectSlug: Scalars['String']['input'];
};

export type VmsCreateCreditInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  filmId: Scalars['String']['input'];
  job?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['String']['input'];
  tmdbId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type VmsCreateIndustryEventInput = {
  description: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type VmsCreateReviewInput = {
  content: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  source: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type VmsCreateSpeakerInput = {
  description: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  physical: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
  virtual: Scalars['Boolean']['input'];
};

export type VmsDeleteAssetInput = {
  id: Scalars['String']['input'];
  isBanner?: InputMaybe<Scalars['Boolean']['input']>;
  isGallery?: InputMaybe<Scalars['Boolean']['input']>;
  isPoster?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['String']['input'];
};

export type VmsDeleteBrandingInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsDeleteCreditInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsDeleteIndustryEventInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsDeleteReviewInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsDeleteSpeakerInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsDeleteSubtitleInput = {
  index: Scalars['Float']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsDeleteVideoReferenceInput = {
  projectId: Scalars['String']['input'];
};

export type VmsInitVideoUpload = {
  __typename?: 'VmsInitVideoUpload';
  fileId: Scalars['String']['output'];
  fileKey: Scalars['String']['output'];
};

export type VmsInitVideoUploadInput = {
  filename: Scalars['String']['input'];
  filmId: Scalars['String']['input'];
  originalFilename: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsListProjectInput = {
  id: Scalars['String']['input'];
  listed: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsPaginatedProjects = {
  __typename?: 'VmsPaginatedProjects';
  items: Array<VmsProject>;
  total: Scalars['Int']['output'];
};

export type VmsPart = {
  __typename?: 'VmsPart';
  partNumber: Scalars['Float']['output'];
  signedUrl: Scalars['String']['output'];
};

export type VmsPayouts = {
  __typename?: 'VmsPayouts';
  connectAccountConfigured: Scalars['Boolean']['output'];
  hasConnectAccount: Scalars['Boolean']['output'];
  hasExternalAccounts: Scalars['Boolean']['output'];
};

export type VmsPresignedUrls = {
  __typename?: 'VmsPresignedUrls';
  parts: Array<VmsPart>;
};

export type VmsPresignedUrlsInput = {
  fileId: Scalars['String']['input'];
  fileKey: Scalars['String']['input'];
  parts: Scalars['Float']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsProject = {
  __typename?: 'VmsProject';
  allowOnDemand: Scalars['Boolean']['output'];
  allowPhysical: Scalars['Boolean']['output'];
  allowRental: Scalars['Boolean']['output'];
  allowVirtual: Scalars['Boolean']['output'];
  approval: Scalars['Boolean']['output'];
  availabilityEnd?: Maybe<Scalars['DateTime']['output']>;
  availabilityStart?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dashUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  drmAssetId?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  film: Film;
  flexTicketEnabled: Scalars['Boolean']['output'];
  geoAllowRestrictions: Array<Scalars['String']['output']>;
  geoBlacklist?: Maybe<Array<Scalars['String']['output']>>;
  geoDenyRestrictions: Array<Scalars['String']['output']>;
  geoRestrictions?: Maybe<Array<Scalars['String']['output']>>;
  geoWhitelist?: Maybe<Array<Scalars['String']['output']>>;
  hasAssets: Scalars['Boolean']['output'];
  hasV2Assets: Scalars['Boolean']['output'];
  hlsUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  licenseOptional: Scalars['Boolean']['output'];
  licenseRate?: Maybe<ProjectRate>;
  listed: Scalars['Boolean']['output'];
  organization?: Maybe<Organization>;
  payouts: VmsPayouts;
  playlistEnabled: Scalars['Boolean']['output'];
  price: Scalars['Int']['output'];
  priceEnabled: Scalars['Boolean']['output'];
  published: Scalars['Boolean']['output'];
  rateCards: Array<RateCard>;
  rentalPrice: Scalars['Int']['output'];
  rentalRate: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
  ticketMax: Scalars['Int']['output'];
  ticketMin: Scalars['Int']['output'];
  ticketRate?: Maybe<ProjectRate>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  visualWatermark: Scalars['Boolean']['output'];
  vmsEnabled: Scalars['Boolean']['output'];
  vmsVideo: VmsVideo;
};

export type VmsPublishProjectInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsSaveSubtitleInput = {
  projectId: Scalars['String']['input'];
  subtitleInfo: VmsSubtitleInput;
};

export type VmsSubtitleInput = {
  filename: Scalars['String']['input'];
  hasCC: Scalars['Boolean']['input'];
  language: Scalars['String']['input'];
  originalFilename: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type VmsUpdateBrandingInput = {
  file?: InputMaybe<Scalars['String']['input']>;
  filmId: Scalars['String']['input'];
  focalPoint?: InputMaybe<FocalPointInput>;
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type VmsUpdateCreditInput = {
  department?: InputMaybe<Scalars['String']['input']>;
  filmId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  job?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['String']['input'];
  tmdbId?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type VmsUpdateFilmTrailerInput = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  trailer?: InputMaybe<Scalars['String']['input']>;
};

export type VmsUpdateIndustryEventInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type VmsUpdateProjectIntroductionInput = {
  description: Scalars['String']['input'];
  /** Primary genre. */
  genre: Scalars['String']['input'];
  /** List of secondary genres. */
  genres: Array<ProjectIntroductionFilmGenreInput>;
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  runtime: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type VmsUpdateReviewInput = {
  content: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  source: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type VmsUpdateRightsManagementInput = {
  allowOnDemand: Scalars['Boolean']['input'];
  allowPhysical: Scalars['Boolean']['input'];
  allowRental: Scalars['Boolean']['input'];
  allowVirtual: Scalars['Boolean']['input'];
  end: Scalars['DateTime']['input'];
  geoBlacklist: Array<Scalars['String']['input']>;
  geoWhitelist: Array<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  licenseOptional: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
  rentalPrice?: InputMaybe<Scalars['Int']['input']>;
  start: Scalars['DateTime']['input'];
  ticketMax?: InputMaybe<Scalars['Int']['input']>;
  ticketMin?: InputMaybe<Scalars['Int']['input']>;
};

export type VmsUpdateSpeakerInput = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  physical: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
  virtual: Scalars['Boolean']['input'];
};

export type VmsVideo = {
  __typename?: 'VmsVideo';
  originalFilename: Scalars['String']['output'];
  subtitles: Array<Subtitle>;
};

export type VodAssets = {
  __typename?: 'VodAssets';
  encodedVideos?: Maybe<Array<EncodedVideo>>;
  encodingJobs?: Maybe<Array<EncodingJob>>;
  playlists?: Maybe<Array<Playlist>>;
  subtitles?: Maybe<Array<Subtitle>>;
};

export type DrmTokenMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
}>;


export type DrmTokenMutation = { __typename?: 'Mutation', drmToken: { __typename?: 'DrmToken', token: string } };

export type DrmTokenSupportMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;


export type DrmTokenSupportMutation = { __typename?: 'Mutation', drmTokenSupport: { __typename?: 'DrmToken', token: string } };

export type DrmTokenRentalMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
  rentalId: Scalars['String']['input'];
}>;


export type DrmTokenRentalMutation = { __typename?: 'Mutation', drmTokenRental: { __typename?: 'DrmToken', token: string } };

export type DrmTokenScreenerMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
  screenerId: Scalars['String']['input'];
  accessCode: Scalars['String']['input'];
}>;


export type DrmTokenScreenerMutation = { __typename?: 'Mutation', drmTokenScreener: { __typename?: 'DrmToken', token: string } };

export type DrmTokenConsoleMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
  assetId: Scalars['String']['input'];
}>;


export type DrmTokenConsoleMutation = { __typename?: 'Mutation', drmTokenConsole: { __typename?: 'DrmToken', token: string } };


export const DrmTokenDocument = gql`
    mutation DrmToken($sessionId: String!, $eventId: String!) {
  drmToken(sessionId: $sessionId, eventId: $eventId) {
    token
  }
}
    `;
export type DrmTokenMutationFn = Apollo.MutationFunction<DrmTokenMutation, DrmTokenMutationVariables>;

/**
 * __useDrmTokenMutation__
 *
 * To run a mutation, you first call `useDrmTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDrmTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [drmTokenMutation, { data, loading, error }] = useDrmTokenMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDrmTokenMutation(baseOptions?: Apollo.MutationHookOptions<DrmTokenMutation, DrmTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DrmTokenMutation, DrmTokenMutationVariables>(DrmTokenDocument, options);
      }
export type DrmTokenMutationHookResult = ReturnType<typeof useDrmTokenMutation>;
export type DrmTokenMutationResult = Apollo.MutationResult<DrmTokenMutation>;
export type DrmTokenMutationOptions = Apollo.BaseMutationOptions<DrmTokenMutation, DrmTokenMutationVariables>;
export const DrmTokenSupportDocument = gql`
    mutation DrmTokenSupport($sessionId: String!) {
  drmTokenSupport(sessionId: $sessionId) {
    token
  }
}
    `;
export type DrmTokenSupportMutationFn = Apollo.MutationFunction<DrmTokenSupportMutation, DrmTokenSupportMutationVariables>;

/**
 * __useDrmTokenSupportMutation__
 *
 * To run a mutation, you first call `useDrmTokenSupportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDrmTokenSupportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [drmTokenSupportMutation, { data, loading, error }] = useDrmTokenSupportMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useDrmTokenSupportMutation(baseOptions?: Apollo.MutationHookOptions<DrmTokenSupportMutation, DrmTokenSupportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DrmTokenSupportMutation, DrmTokenSupportMutationVariables>(DrmTokenSupportDocument, options);
      }
export type DrmTokenSupportMutationHookResult = ReturnType<typeof useDrmTokenSupportMutation>;
export type DrmTokenSupportMutationResult = Apollo.MutationResult<DrmTokenSupportMutation>;
export type DrmTokenSupportMutationOptions = Apollo.BaseMutationOptions<DrmTokenSupportMutation, DrmTokenSupportMutationVariables>;
export const DrmTokenRentalDocument = gql`
    mutation DrmTokenRental($sessionId: String!, $rentalId: String!) {
  drmTokenRental(input: {sessionId: $sessionId, rentalId: $rentalId}) {
    token
  }
}
    `;
export type DrmTokenRentalMutationFn = Apollo.MutationFunction<DrmTokenRentalMutation, DrmTokenRentalMutationVariables>;

/**
 * __useDrmTokenRentalMutation__
 *
 * To run a mutation, you first call `useDrmTokenRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDrmTokenRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [drmTokenRentalMutation, { data, loading, error }] = useDrmTokenRentalMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      rentalId: // value for 'rentalId'
 *   },
 * });
 */
export function useDrmTokenRentalMutation(baseOptions?: Apollo.MutationHookOptions<DrmTokenRentalMutation, DrmTokenRentalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DrmTokenRentalMutation, DrmTokenRentalMutationVariables>(DrmTokenRentalDocument, options);
      }
export type DrmTokenRentalMutationHookResult = ReturnType<typeof useDrmTokenRentalMutation>;
export type DrmTokenRentalMutationResult = Apollo.MutationResult<DrmTokenRentalMutation>;
export type DrmTokenRentalMutationOptions = Apollo.BaseMutationOptions<DrmTokenRentalMutation, DrmTokenRentalMutationVariables>;
export const DrmTokenScreenerDocument = gql`
    mutation DrmTokenScreener($sessionId: String!, $screenerId: String!, $accessCode: String!) {
  drmTokenScreener(
    sessionId: $sessionId
    screenerId: $screenerId
    accessCode: $accessCode
  ) {
    token
  }
}
    `;
export type DrmTokenScreenerMutationFn = Apollo.MutationFunction<DrmTokenScreenerMutation, DrmTokenScreenerMutationVariables>;

/**
 * __useDrmTokenScreenerMutation__
 *
 * To run a mutation, you first call `useDrmTokenScreenerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDrmTokenScreenerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [drmTokenScreenerMutation, { data, loading, error }] = useDrmTokenScreenerMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      screenerId: // value for 'screenerId'
 *      accessCode: // value for 'accessCode'
 *   },
 * });
 */
export function useDrmTokenScreenerMutation(baseOptions?: Apollo.MutationHookOptions<DrmTokenScreenerMutation, DrmTokenScreenerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DrmTokenScreenerMutation, DrmTokenScreenerMutationVariables>(DrmTokenScreenerDocument, options);
      }
export type DrmTokenScreenerMutationHookResult = ReturnType<typeof useDrmTokenScreenerMutation>;
export type DrmTokenScreenerMutationResult = Apollo.MutationResult<DrmTokenScreenerMutation>;
export type DrmTokenScreenerMutationOptions = Apollo.BaseMutationOptions<DrmTokenScreenerMutation, DrmTokenScreenerMutationVariables>;
export const DrmTokenConsoleDocument = gql`
    mutation DrmTokenConsole($sessionId: String!, $assetId: String!) {
  drmTokenConsole(sessionId: $sessionId, assetId: $assetId) {
    token
  }
}
    `;
export type DrmTokenConsoleMutationFn = Apollo.MutationFunction<DrmTokenConsoleMutation, DrmTokenConsoleMutationVariables>;

/**
 * __useDrmTokenConsoleMutation__
 *
 * To run a mutation, you first call `useDrmTokenConsoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDrmTokenConsoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [drmTokenConsoleMutation, { data, loading, error }] = useDrmTokenConsoleMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useDrmTokenConsoleMutation(baseOptions?: Apollo.MutationHookOptions<DrmTokenConsoleMutation, DrmTokenConsoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DrmTokenConsoleMutation, DrmTokenConsoleMutationVariables>(DrmTokenConsoleDocument, options);
      }
export type DrmTokenConsoleMutationHookResult = ReturnType<typeof useDrmTokenConsoleMutation>;
export type DrmTokenConsoleMutationResult = Apollo.MutationResult<DrmTokenConsoleMutation>;
export type DrmTokenConsoleMutationOptions = Apollo.BaseMutationOptions<DrmTokenConsoleMutation, DrmTokenConsoleMutationVariables>;